<template>
  <tr>
    <DataAlertItemColumn
      v-for="(column, indexColumn) in columns"
      :key="indexColumn"
      :column="column"
      :item="item"
      />
    <td v-if="viewName">
      <router-link :to="{name: viewName, params: routerParams}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import DataAlertItemColumn from './data-alert-item-column';
export default {
  name: 'DataAlertItem',
  props: [
    'item',
    'viewName',
    'columns',
    'params'
  ],
  components: {
    DataAlertItemColumn
  },
  computed: {
    routerParams() {
      const temp = {};
      (this.params || []).forEach((p) => { temp[p.prop] = this.item?.[p.value]; });
      return temp;
    }
  }
};
</script>
