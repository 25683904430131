var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission('access:developers'))?_c('div',[_c('DogWaldo',{attrs:{"size":10}}),_c('vue-headful',{attrs:{"title":"Vue Components"}}),_c('div',{staticClass:"top25"},[_c('h1',[_vm._v("Generic Components")]),_vm._m(0),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('zg-button',{staticClass:"zg-btn-default",attrs:{"isEnable":true,"isSubmiting":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Name of Button ")]},proxy:true}],null,false,1059206117)})],1),_vm._m(1)]),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('zg-button',{staticClass:"zg-btn-ligth",attrs:{"isEnable":false,"isSubmitting":false},on:{"click":_vm.makeClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Name of Button ")]},proxy:true}],null,false,1059206117)})],1),_vm._m(2)]),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('zg-button',{staticClass:"zg-btn-danger",attrs:{"isEnable":false,"isSubmitting":true},on:{"click":_vm.makeClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Name of Button ")]},proxy:true}],null,false,1059206117)})],1),_vm._m(3)])]),_vm._m(4),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('zg-card',[_vm._v(" This is a Card ")])],1),_vm._m(5)]),_vm._m(6),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('zg-contact',{attrs:{"contact":_vm.contactTemplate}})],1),_vm._m(7)]),_vm._m(8),_c('div',{staticClass:"row m-4"},[_c('div',{staticClass:"col m-2"},[_c('Pagination',{attrs:{"page":_vm.paginationModel.page,"totalPages":_vm.paginationModel.totalPages},on:{"update:page":function($event){return _vm.$set(_vm.paginationModel, "page", $event)}}})],1),_vm._m(9)])],1):_c('div',[_vm._v(" Your account does not have the right permissions to see this page ")])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col m-2"},[_c('h2',[_vm._v("Button")]),_c('p',[_vm._v(" Generic component to use as Button. There are three default styles: zg-btn-default, zg-btn-ligth, zg-btn-danger Also you can use the following properties to change behaviour of the button. "),_c('ul',[_c('li',[_c('b',[_vm._v("isEnable:")]),_vm._v(" Boolean to enable the button.")]),_c('li',[_c('b',[_vm._v("isSubmitting:")]),_vm._v(" Boolean to show loading icon.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Standard Button "),_c('ul',[_c('li',[_vm._v("className: btn-zg-default")]),_c('li',[_vm._v("@click: function to call")]),_c('li',[_vm._v("isEnable: true")]),_c('li',[_vm._v("isSubmitting: false")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Disabled Button "),_c('ul',[_c('li',[_vm._v("className: btn-zg-light")]),_c('li',[_vm._v("@click: function to call")]),_c('li',[_vm._v("isEnable: false")]),_c('li',[_vm._v("isSubmitting: true")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Submitting Button "),_c('ul',[_c('li',[_vm._v("className: zg-btn-danger")]),_c('li',[_vm._v("@click: function to call")]),_c('li',[_vm._v("isEnable: false")]),_c('li',[_vm._v("isSubmitting: false")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col m-2"},[_c('h2',[_vm._v("Card")]),_c('p',[_vm._v("Generic component to use as Card. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Standard Card "),_c('ul',[_c('li',[_vm._v("v-slot:content: Connect HTML")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col m-2"},[_c('h2',[_vm._v("Contact")]),_c('p',[_vm._v("Generic component to use as Contact. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Standard Contact "),_c('ul',[_c('li',[_vm._v(" v-bind:contact: Data contact "),_c('br'),_vm._v(" { first_name: 'First', middle_name: 'Middle', last_name: 'Last name', title: 'Title', suffix: 'Suffix', } ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col m-2"},[_c('h2',[_vm._v("Pagination")]),_c('p',[_vm._v("Generic component to use as Pagination. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_vm._v(" Standard Pagination "),_c('ul',[_c('li',[_vm._v("v-bind:page.sync: current page")]),_c('li',[_vm._v("v-bind:totalPages: total of pages")]),_c('li',[_vm._v(" We use vuex-pagination as library, we create instance for the list of objects that we are using. "),_c('br'),_vm._v(" { ..., page: 1, totalPages: 5, ... } ")])])])
}]

export { render, staticRenderFns }