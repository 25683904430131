<template>
  <div v-if="hasPermission('access:developers')">
    <vue-headful title="Data Alerts" />
    <div class="top25 mb-2">
      <h1>Data Alerts</h1>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Identifier</th>
          <th>Description</th>
          <th>Status</th>
          <th>Report Rows</th>
        </tr>
      </thead>
      <tbody>
        <DataAlertsListItem v-for="dataAlert in dataAlerts" :key="dataAlert.id" :dataAlert="dataAlert" />
      </tbody>
    </table>
  </div>
  <div v-else>
    Your account does not have the right permissions to see this page
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { hasPermissionMixin } from '../../mixins';
import DataAlertsListItem from './data-alerts-list-item';

export default {
  name: 'DataAlertsList',
  components: {
    DataAlertsListItem
  },

  mixins: [hasPermissionMixin],

  computed: {
    ...mapState('dataAlerts', ['dataAlerts'])
  },
  mounted() {
    this.$store.dispatch('dataAlerts/fetchDataAlerts');
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
</style>
