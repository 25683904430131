<template>
  <td v-if="routerLink">
    <router-link :to="{name: routerLink.viewName, params: routerLink.params}" class="btn btn-outline-info">
      {{ value }}
    </router-link>
  </td>
  <td v-else>
    {{ value }}
  </td>
</template>

<script>
export default {
  name: 'DataAlertItemColumn',
  props: [
    'column',
    'item'
  ],
  computed: {
    value() {
      return this.item[this.column];
    },
    routerLink() {
      if (this.column === 'business_entity' || this.column === 'business_entity_uuid') {
        return {'viewName': "BusinessEntity", "params": {"businessEntityUuid": this.value}};
      }
      else if (this.column === 'account' || this.column === 'account_uuid') {
        return {'viewName': "Account", "params": {"accountUuid": this.value}};
      }
      else if (this.column === 'order' || this.column === 'order_uuid') {
        return {'viewName': "Order", "params": {"uuid": this.value}};
      }
      else if (this.column === 'order_task' || this.column === 'order_task_uuid') {
        const orderUuid = this.item?.order ?? this.item?.order_uuid;
        if (orderUuid) {
          return {'viewName': "OrderServiceDataView", "params": {"uuid": orderUuid, "taskUuid": this.value}};
        }
      }
      return false;
    }
  }
}
</script>
