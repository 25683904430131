<template>
  <tr>
    <td>
      <router-link :to="{ name: 'DataAlert', params: { id: dataAlert.id } }" class="btn btn-outline-info">
        {{ dataAlert.id }}
      </router-link>
    </td>
    <td>{{ dataAlert.description }}</td>
    <td>{{ dataAlert.humanStatus }}</td>
    <td :class="{'text-danger': dataAlert.status != 'complete'}">{{ countOrNone }}</td>
  </tr>
</template>

<script>
export default {
  name: 'DataAlertsListItem',
  props: ['dataAlert'],
  computed: {
    countOrNone() {
      if (this.dataAlert.count === null) {
        return "No data"
      }
      return this.dataAlert.count;
    }
  }
};
</script>
