<template>
  <div v-if="hasPermission('access:developers')">
    <vue-headful title="Order creation error handling" />
    <div class="top25">
      <div class="row mb-2">
        <div>
          <h1>Order creation error handling</h1>
        </div>
      </div>

      <CollapsiblePanel :titleBorder="false">
        <template v-slot:title>Reprocess Order Without Tasks</template>
        <template v-slot:content>
          <div class="fullwidth d-flex justify-content-start">
            <ZgButton
              class="zg-btn-default zg-text-sm"
              @click="runReprocessOrderWithoutTasks"
              type="button"
              :isSubmitting="dataReprocessOrderWithoutTasks.loading"
              :isEnable="!dataReprocessOrderWithoutTasks.disabled"
            >
              <template v-slot:title>Run</template>
            </ZgButton>
          </div>
          <div class="row mb-2">
            <table class="table" v-if="stateReprocessOrderWithoutTasks">
              <thead>
                <tr>
                  <th scope="col">Order uuid</th>
                  <th scope="col">Account uuid</th>
                  <th scope="col">Order creation status</th>
                  <th scope="col">Errors</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="elem in stateReprocessOrderWithoutTasks"
                  v-bind:key="elem.order_uuid"
                >
                  <td>{{ elem.order_uuid }}</td>
                  <td>{{ elem.account_uuid }}</td>
                  <td>{{ elem.order_creation_status }}</td>
                  <td>{{ elem.errors }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </CollapsiblePanel>

      <CollapsiblePanel :titleBorder="false">
        <template v-slot:title>Reprocess Order Tasks Created</template>
        <template v-slot:content>
          <div class="fullwidth d-flex justify-content-start">
            <ZgButton
              class="zg-btn-default zg-text-sm"
              @click="runReprocessOrderTasksCreated"
              type="button"
              :isSubmitting="dataReprocessOrderTasksCreated.loading"
              :isEnable="!dataReprocessOrderTasksCreated.disabled"
            >
              <template v-slot:title>Run</template>
            </ZgButton>
          </div>
          <div class="row mb-2">
            <table class="table" v-if="stateReprocessOrderTasksCreated">
              <thead>
                <tr>
                  <th scope="col">Order uuid</th>
                  <th scope="col">Account uuid</th>
                  <th scope="col">Order creation status</th>
                  <th scope="col">Errors</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="elem in stateReprocessOrderTasksCreated"
                  v-bind:key="elem.order_uuid"
                >
                  <td>{{ elem.order_uuid }}</td>
                  <td>{{ elem.account_uuid }}</td>
                  <td>{{ elem.order_creation_status }}</td>
                  <td>{{ elem.errors }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </CollapsiblePanel>

      <CollapsiblePanel :titleBorder="false">
        <template v-slot:title>Reprocess Completed Order</template>
        <template v-slot:content>
          <div class="fullwidth d-flex justify-content-start">
            <ZgButton
              class="zg-btn-default zg-text-sm"
              @click="runReprocessCompletedOrder"
              type="button"
              :isSubmitting="dataReprocessCompletedOrder.loading"
              :isEnable="!dataReprocessCompletedOrder.disabled"
            >
              <template v-slot:title>Run</template>
            </ZgButton>
          </div>
          <div class="row mb-2">
            <table class="table" v-if="stateReprocessCompletedOrder">
              <thead>
                <tr>
                  <th scope="col">Order uuid</th>
                  <th scope="col">Account uuid</th>
                  <th scope="col">Order creation status</th>
                  <th scope="col">Errors</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="elem in stateReprocessCompletedOrder"
                  v-bind:key="elem.order_uuid"
                >
                  <td>{{ elem.order_uuid }}</td>
                  <td>{{ elem.account_uuid }}</td>
                  <td>{{ elem.order_creation_status }}</td>
                  <td>{{ elem.errors }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </CollapsiblePanel>

      <CollapsiblePanel :titleBorder="false">
        <template v-slot:title>Bulk Send Orders to Salesforce</template>
        <template v-slot:content>
          <div class="fullwidth d-flex justify-content-start">
            <ZgButton class="zg-btn-default zg-text-sm" @click="runBulkSendOrdersToSalesforce" type="button"
              :isSubmitting="dataBulkSendOrdersToSalesforce.loading"
              :isEnable="!dataBulkSendOrdersToSalesforce.disabled">
              <template v-slot:title>Sync</template>
            </ZgButton>
          </div>
          <div v-if="stateBulkSendOrdersToSalesforce">
              <p>Total Orders enqueued: {{ stateBulkSendOrdersToSalesforce.enqueued }}</p>
          </div>
        </template>
      </CollapsiblePanel>

      <CollapsiblePanel :titleBorder="false">
        <template v-slot:title> Missed Charge Events </template>
        <template v-slot:content>
          <div class="fullwidth d-flex justify-content-start">
            <ZgButton
              class="zg-btn-default zg-text-sm"
              @click="getMissedChargeEvents"
              type="button"
              :isSubmitting="dataMissedChargeEvents.loading"
              :isEnable="!dataMissedChargeEvents.disabled"
            >
              <template v-slot:title>Get</template>
            </ZgButton>
          </div>
          <div class="row mb-2">
            <table class="table" v-if="stateMissedChargeEvents">
              <thead>
                <tr>
                  <th scope="col">Order billing uuid</th>
                  <th scope="col">Order created at</th>
                  <th scope="col">Total charged</th>
                  <th scope="col">External billing id</th>
                  <th scope="col">External invoice amount due</th>
                  <!-- <th scope="col">External invoice id</th>
                  <th scope="col">External billing event id</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="elem in stateMissedChargeEvents"
                  v-bind:key="elem.order_billing_uuid"
                >
                  <td>{{ elem.order_billing_uuid }}</td>
                  <td>{{ elem.order_created_at }}</td>
                  <td>{{ elem.total_charged }}</td>
                  <td>{{ elem.external_billing_id }}</td>
                  <td>{{ elem.external_invoice_amount_due }}</td>
                  <!-- <td>{{ elem.external_invoice_id }}</td>
                  <td>{{ elem.external_invoice_id }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </CollapsiblePanel>
    </div>
  </div>
  <div v-else>
    Your account does not have the right permissions to see this page
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CollapsiblePanel from "@/components/common/collapsible-panel";
import ZgButton from "@/components/common/Generics/ZgButton";
import { hasPermissionMixin, manifestMixin } from "../mixins";

export default {
  name: "RetryOrderCreation",
  components: { CollapsiblePanel, ZgButton },

  mixins: [hasPermissionMixin, manifestMixin],

  data() {
    return {
      dataReprocessOrderWithoutTasks: {
        loading: false,
        disabled: false,
      },
      dataReprocessOrderTasksCreated: {
        loading: false,
        disabled: false,
      },
      dataReprocessCompletedOrder: {
        loading: false,
        disabled: false,
      },
      dataMissedChargeEvents: {
        loading: false,
        disabled: false,
      },
      dataBulkSendOrdersToSalesforce: {
        loading: false,
        disabled: false,
      }
    };
  },

  computed: {
    ...mapState({
      stateReprocessOrderWithoutTasks: (state) =>
        state.orders.retryOrderCreation.reprocessOrderWithoutTasks,
      stateReprocessOrderTasksCreated: (state) =>
        state.orders.retryOrderCreation.reprocessOrderTasksCreated,
      stateReprocessCompletedOrder: (state) =>
        state.orders.retryOrderCreation.reprocessCompletedOrder,
      stateMissedChargeEvents: (state) =>
        state.orders.retryOrderCreation.missedChargeEvents,
      stateBulkSendOrdersToSalesforce: (state) =>
        state.orders.retryOrderCreation.bulkSendOrdersToSalesforce,
    }),
  },
  methods: {
    ...mapActions({
      reprocessOrderWithoutTasks: "orders/reprocessOrderWithoutTasks",
      reprocessOrderTasksCreated: "orders/reprocessOrderTasksCreated",
      reprocessCompletedOrder: "orders/reprocessCompletedOrder",
      missedChargeEvents: "orders/missedChargeEvents",
      bulkSendOrdersToSalesforce: "orders/bulkSendOrdersToSalesforce",
    }),
    runBulkSendOrdersToSalesforce() {
      this.dataBulkSendOrdersToSalesforce.disabled = true;
      this.dataBulkSendOrdersToSalesforce.loading = true;
      this.bulkSendOrdersToSalesforce().then(() => {
        this.dataBulkSendOrdersToSalesforce.loading = false;
      });
    },
    runReprocessOrderWithoutTasks() {
      this.dataReprocessOrderWithoutTasks.disabled = true;
      this.dataReprocessOrderWithoutTasks.loading = true;
      this.reprocessOrderWithoutTasks().then(() => {
        this.dataReprocessOrderWithoutTasks.loading = false;
      });
    },
    runReprocessOrderTasksCreated() {
      this.dataReprocessOrderTasksCreated.disabled = true;
      this.dataReprocessOrderTasksCreated.loading = true;
      this.reprocessOrderTasksCreated().then(() => {
        this.dataReprocessOrderTasksCreated.loading = false;
      });
    },
    runReprocessCompletedOrder() {
      this.dataReprocessCompletedOrder.disabled = true;
      this.dataReprocessCompletedOrder.loading = true;
      this.reprocessCompletedOrder().then(() => {
        this.dataReprocessCompletedOrder.loading = false;
      });
    },
    getMissedChargeEvents() {
      this.dataMissedChargeEvents.disabled = true;
      this.dataMissedChargeEvents.loading = true;
      this.missedChargeEvents().then(() => {
        this.dataMissedChargeEvents.loading = false;
      });
    },
  },
};
</script>

<style lang="scss"></style>
