<template>
  <div v-if="hasPermission('access:developers')">
    <vue-headful :title="'Data Alert' + ' - ' + id" />
    <div class="container">
      <div class="row justify-content-end">
        <div class="col col-auto">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Report Details</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Status: {{ dataAlert.humanStatus }}</li>
                <li v-show="dataAlert.status == 'complete'" class="list-group-item">Report Date: {{ dataAlert.humanLastReport}}</li>
                <li v-show="startUpdateResponse" class="list-group-item" :class="{'text-danger': updateErrors}">Start update response: {{ startUpdateResponse }}</li>
              </ul>
              <div v-show="!startUpdateResponse" @click.once="startReportRun" class="btn btn-warning card-link">Start New Report Run</div>
              <div v-show="startUpdateResponse" @click="reloadDataAlert" class="btn btn-primary card-link">Reload page</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="reportExists">
      <table class="table table-sm caption-top">
        <caption>{{ dataAlert.description }} <span class="badge bg-secondary">Count: {{ dataAlert.count }}</span></caption>
        <thead>
          <tr>
            <th v-for="(column, index) in dataAlert.columns" :key="index"> {{ column }}</th>
            <th v-if="dataAlert.viewName">Detail</th>
          </tr>
        </thead>
        <tbody>
          <DataAlertItem
          v-for="(item, index) in dataAlertRecords.items"
          :key="index"
          :item="item"
          :viewName="dataAlert.viewName"
          :columns="dataAlert.columns"
          :params="dataAlert.params"
          />
        </tbody>
      </table>
      <Pagination :page.sync="dataAlertRecords.page" :totalPages="dataAlertRecords.totalPages" v-if="recordsExist" />
    </div>
    <div v-else>
      <h1>{{ dataAlert.description }}</h1>
      <div v-show="dataAlert.count === 0">
        <h2 class="px-3">Count: {{ dataAlert.count }}</h2>
      </div>
      <div v-show="dataAlert.status == 'errored'">
        <h2 class="px-3">Report error details:</h2>
        <pre class="px-3">{{ dataAlert.error_details}}</pre>
      </div>
    </div>
  </div>
  <div v-else>
    Your account does not have the right permissions to see this page
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createInstance } from 'vuex-pagination';
import { hasPermissionMixin } from '../../mixins';
import DataAlertItem from './data-alert-item';
import Pagination from '../../utils/pagination';

export default {
  name: 'DataAlert',
  mixins: [hasPermissionMixin],
  props: ['id'],

  components: {
    DataAlertItem,
    Pagination
  },
  computed: {
    ...mapState('dataAlerts', ['dataAlert', 'startUpdateResponse', 'updateErrors']),
    dataAlertRecords: createInstance('dataAlertRecords', {
      page: 1,
      pageSize: 10,
      args() {
        return {
          dataAlertId: this.id
        };
      }
    }),
    recordsExist() {
      return this.dataAlertRecords.totalPages > 1;
    },
    reportExists() {
      return this.dataAlert.count && this.dataAlert.count > 0;
    }
  },
  methods: {
    startReportRun() {
      this.$store.dispatch('dataAlerts/startReportRun', {id: this.id });
    },
    reloadDataAlert() {
      window.location.reload();
    }
  },
  mounted() {
    this.$store.commit('dataAlerts/resetReportRunStatus');
    this.$store.dispatch('dataAlerts/fetchDataAlert', { id: this.id });
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
</style>
