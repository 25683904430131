<template>
    <p>
      {{contact.first_name}}{{contact.middle_name?` ${contact.middle_name}`:''}}{{` ${contact.last_name}`}}{{contact.suffix?` ${contact.suffix}`:''}}{{contact.title?`, ${contact.title}`:''}}
    </p>    
</template>

<script>

export default {
  name: 'Contact',

  props: {
    contact: {
      type: Object,
      default() {
        return {
          first_name: '',
          middle_name: '',
          last_name: '',
          title: '',
          suffix: '',
        };
      }
    }
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>

</style>
