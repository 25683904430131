<template>
  <div v-if="!hide" :style="dynamicDivStyle">
    <span :style="dynamicSpanStyle">Until the next time!<br/>&hearts; Cristian</span>
    <img 
      src="@/assets/img/waldo.svg"
      :style="dynamicImgStyle"
      @click="hideDog"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    />
  </div>
</template>

<script>

export default {
  name: 'DogWaldo',
  data: function() {
    const numberOfDaysWithoutWaldo = 4;
    let referenceDate = new Date();
    referenceDate.setDate(referenceDate.getDate() - numberOfDaysWithoutWaldo)
    const lastClickDate = new Date(localStorage.getItem('last_click_waldo'));
    const isHidden = lastClickDate > referenceDate;
    return {
      hide: isHidden,
      hover: false,
      randomTop: Math.floor(Math.random()*80)+10,
      randomLeft: Math.floor(Math.random()*80)+10
    }
  },
  props: {
    'size': {
      type: Number,
      default: 50
    }
  },
  computed: {
    dynamicImgStyle() {
      return `width: ${this.size}em`;
    },
    dynamicSpanStyle() {
      return `visibility: ${!this.hover?'hidden':'visible'};`;
    },
    dynamicDivStyle() {
      return `top: ${this.randomTop}%; left: ${this.randomLeft}%;`
    },
    cssProps() {
      return {
        '--randomTop': this.randomTop + "%",
        '--randomLeft': this.randomLeft + "%",
      }
    }
  },
  methods: {
    hideDog() {
      this.hide = true
      localStorage.setItem('last_click_waldo', new Date())
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    position: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: block;
      font-weight: bold;
      position: relative;
      background-color: $clr-primary;
      color: $clr-white;
      text-align: center;
      border-radius: 1em;
      padding: 0.5em;
      &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          z-index: 1;
          border-style: solid;
          border-color: $clr-primary transparent;
          border-width: 10px 10px 0;
          bottom: -10px;
          left: 50%;
          margin-left: -10px;
      }
    }
    
    img {
      display: block;
      width: 100%;
      margin-top: 20px;
    }
  }
</style>
