<template>
  <div v-if="hasPermission('access:developers')">
    <DogWaldo :size=10></DogWaldo>
    <vue-headful title="Vue Components" />
    <div class="top25">
      <h1>Generic Components</h1>
      <div class="row m-2">
        <div class="col m-2">
          <h2>Button</h2>
          <p>
            Generic component to use as Button.
            There are three default styles: zg-btn-default, zg-btn-ligth, zg-btn-danger
            Also you can use the following properties to change behaviour of the button.
            <ul>
              <li><b>isEnable:</b> Boolean to enable the button.</li>
              <li><b>isSubmitting:</b> Boolean to show loading icon.</li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row m-4">
        <div class="col m-2">
          <zg-button
            class="zg-btn-default"
            :isEnable="true"
            :isSubmiting="false">
            <template v-slot:title>
              Name of Button
            </template>
          </zg-button>
        </div>
        <div class="col">
          Standard Button
          <ul>
            <li>className: btn-zg-default</li>
            <li>@click: function to call</li>
            <li>isEnable: true</li>
            <li>isSubmitting: false</li>
          </ul>
        </div>
      </div>
      <div class="row m-4">
        <div class="col m-2">
          <zg-button
          class="zg-btn-ligth"
            @click="makeClick"
            :isEnable="false"
            :isSubmitting="false">
            <template v-slot:title>
              Name of Button
            </template>
          </zg-button>
        </div>
        <div class="col">
          Disabled Button
          <ul>
            <li>className: btn-zg-light</li>
            <li>@click: function to call</li>
            <li>isEnable: false</li>
            <li>isSubmitting: true</li>
          </ul>
        </div>
      </div>
      <div class="row m-4">
        <div class="col m-2">
          <zg-button
          class="zg-btn-danger"
            @click="makeClick"
            :isEnable="false"
            :isSubmitting="true">
            <template v-slot:title>
              Name of Button
            </template>
          </zg-button>
        </div>
        <div class="col">
          Submitting Button
          <ul>
            <li>className: zg-btn-danger</li>
            <li>@click: function to call</li>
            <li>isEnable: false</li>
            <li>isSubmitting: false</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row m-2">
        <div class="col m-2">
          <h2>Card</h2>
          <p>Generic component to use as Card. </p>
        </div>
      </div>
    <div class="row m-4">
      <div class="col m-2">
        <zg-card>
          This is a Card
        </zg-card>
      </div>
      <div class="col">
        Standard Card
        <ul>
          <li>v-slot:content: Connect HTML</li>
        </ul>
      </div>
    </div>
    <div class="row m-2">
        <div class="col m-2">
          <h2>Contact</h2>
          <p>Generic component to use as Contact. </p>
        </div>
      </div>
    <div class="row m-4">
      <div class="col m-2">
        <zg-contact :contact=contactTemplate>
        </zg-contact>
      </div>
      <div class="col">
        Standard Contact
        <ul>
          <li>
            v-bind:contact: Data contact
            <br>
            {
              first_name: 'First',
              middle_name: 'Middle',
              last_name: 'Last name',
              title: 'Title',
              suffix: 'Suffix',
            }
          </li>
        </ul>
      </div>
    </div>
    <div class="row m-2">
        <div class="col m-2">
          <h2>Pagination</h2>
          <p>Generic component to use as Pagination. </p>
        </div>
      </div>
    <div class="row m-4">
      <div class="col m-2">
        <Pagination :page.sync="paginationModel.page" :totalPages="paginationModel.totalPages" />
      </div>
      <div class="col">
        Standard Pagination
        <ul>
          <li>v-bind:page.sync: current page</li>
          <li>v-bind:totalPages: total of pages</li>
          <li>
            We use vuex-pagination as library, we create instance for the list of objects that we are using.
            <br/>
            {
              ...,
              page: 1,
              totalPages: 5,
              ...
            }
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    Your account does not have the right permissions to see this page
  </div>
</template>

<script>
import ZgButton from '../common/Generics/ZgButton.vue';
import ZgCard from '../common/Generics/ZgCard.vue';
import ZgContact from '@/components/common/Generics/ZgContact.vue';
import Pagination from '@/components/utils/pagination.vue';
import { hasPermissionMixin, manifestMixin } from '../mixins';
import DogWaldo from '@/components/developers/easter-eggs/dog-waldo.vue';

export default {
  components: { ZgButton, ZgCard, ZgContact, Pagination, DogWaldo },
  name: 'Components',

  mixins: [hasPermissionMixin, manifestMixin],

  data() {
    return {
      contactTemplate: {
        first_name: 'First',
        middle_name: 'Middle',
        last_name: 'Last name',
        title: 'Title',
        suffix: 'Suffix',
      },
      paginationModel: {
        page: 1,
        totalPages: 5
      }
    }
  },

  methods: {
    makeClick() {
    }
  }
};
</script>

<style lang="scss">
</style>